.nav-holder {
	background-color: $bg-color;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 2.5vh;
	background-color: $bg-color;
	border-radius: 1rem;
	padding: 3rem 2rem;
}

.appear {
	animation: appear 3s ease-out 1s;
	animation-fill-mode: forwards;
}
