.nav {
	&__logo {
		font-size: 2.5rem;
		font-weight: 500;
		list-style: none;
		padding: 0 2rem;
		opacity: 0;

		@include respond(phone) {
			font-size: 2rem;
			padding-right: 2rem;
		}
	}

	&__item {
		font-size: 1.8rem;
		font-weight: 300;
		list-style: none;
		padding: 0 2rem;
		opacity: 0;

		@include respond(phone) {
			font-size: 1.5rem;
			padding: 0 0.5rem;
		}
	}
}

.nav a {
	text-decoration: none;
	color: $text-color;
}

.welcome__text {
	position: absolute;
	left: 0%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	text-align: left;
	color: black;
	background-color: black;
	border-radius: 15px;
	padding: 5rem;
	opacity: 1;
	animation: typingfade 3s ease-in forwards,
		backgroundfade 1s ease-in 1s forwards;

	@include respond(tab-port) {
		border-radius: 0;
	}

	@include respond(phone) {
		border-radius: 0;
		padding: 2rem;
	}

	&--holder {
		width: 70%;
	}

	&--main,
	&--intro {
		width: 75%;
		font-size: 5rem;
		font-weight: 100;

		@include respond(tab-port) {
			font-size: 4.25rem;
		}

		@include respond(phone) {
			font-size: 2.5rem;
		}
	}
}

.about__header {
	text-align: center;
}

h2 {
	font-size: 3rem;
	font-weight: 100;

	@include respond(phone) {
		font-size: 2.5rem;
	}
}

h3 {
	font-size: 2.35rem;
	font-weight: 300;
	margin: 0;
}

h4 {
	font-size: 2rem;
	font-weight: 300;
	margin-bottom: 0.25rem;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	text-align: justify;
}
