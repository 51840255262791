.logviewer {
	display: flex;
	flex-direction: column;
	font-size: 1.75rem;
	line-height: 150%;
	padding: 10rem 25rem 0 25rem;

	@include respond(tab-land) {
		padding: 2.5rem 15rem;
	}

	@include respond(tab-port) {
		padding: 2.5rem 8rem;
	}

	@include respond(phone) {
		padding: 3rem;
	}

	@include respond(big-desktop) {
		padding: 0 25rem;
	}

	&__header {
		text-align: center;
		margin: 5rem 0;

		@include respond(phone) {
			margin: 2rem 0;
		}
	}

	&__image {
		&--screenshot {
			width: 100%;
			margin: 2.5rem auto 5rem auto;
			box-shadow: $image-shadow;
		}
		&--figma {
			width: 50%;
			margin-right: 3rem;
			box-shadow: $image-shadow;

			@include respond(tab-port) {
				width: 80%;
				margin: 2rem auto;
			}
		}

		&--color-focus {
			width: 50%;
			margin-left: 3rem;
			box-shadow: $image-shadow;

			@include respond(tab-port) {
				width: 80%;
				margin: 2rem auto;
			}
		}

		&--mobile-view {
			height: 45vh;
			width: auto;
			margin: 1rem 5rem;
			// margin-right: 3rem;
			box-shadow: $image-shadow;

			// @include respond(tab-port) {
			// 	width: 70%;
			// }
		}
	}
}

.section {
	&__tools {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5rem 0;

		@include respond(tab-port) {
			flex-direction: column-reverse;
		}

		@include respond(phone) {
			margin: 3rem 0;
		}

		&-content {
			display: flex;
			flex-direction: column;
		}
	}

	&__process {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5rem 0;

		@include respond(tab-port) {
			flex-direction: column;
		}

		@include respond(phone) {
			margin: 3rem 0;
		}

		&-content {
			display: flex;
			flex-direction: column;
		}
	}

	&__build {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5rem 0;

		@include respond(tab-port) {
			flex-direction: column-reverse;
		}

		@include respond(phone) {
			margin: 3rem 0;
		}

		&-content {
			display: flex;
			flex-direction: column;
		}
	}

	&__learned {
		margin: 5rem 0;

		@include respond(phone) {
			margin: 3rem 0;
		}
	}
}

.closing-p {
	margin-top: 3rem;
}

.disclaimer {
	font-size: 1rem;
	margin-top: 3rem;
}
