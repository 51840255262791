*,
*::after,
*::before {
	// margin: 0px;
	// padding: 0px;
	box-sizing: border-box;
}

html {
	//This defines what 1rem is
	font-size: 62.5%; //1rem = 10px; 10px / 16px = 62.5%

	@include respond(phone) {
		font-size: 30%; //1rem = 8px, 6.4/16 = 40%
	}

	@include respond(tab-port) {
		font-size: 50%; //1rem = 8px, 8/16 = 50%
	}

	@include respond(tab-land) {
		font-size: 56.25%; //1rem = 9px, 8/16 = 56.25%
	}

	@include respond(big-desktop) {
		font-size: 75%; //1rem = 12px, 12/16 = 75%
	}
}

body {
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	margin: 0;
	padding: 0;
	line-height: 1.35;
}

.container {
	background-color: $bg-color;
}
