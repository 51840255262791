// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900:      Tablet Portrait
900 - 1200px:   Tablet Landscape
1200 - 1800:    Standard Desktop where normal styles apply
1800px + :      Big Desktop

- phone         600px / 16px = 37.5em
- tab-port      900px / 16px = 56.25em
- tab-land      1200px / 16px = 75em
- big-desktop   1800px / 16px = 112.5em
*/
@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media (max-width: 37.5em) {
			@content;
		}
	}
	@if $breakpoint == tab-port {
		@media (max-width: 56.25em) {
			@content;
		}
	}
	@if $breakpoint == tab-land {
		@media (max-width: 75em) {
			@content;
		}
	}
	@if $breakpoint == big-desktop {
		@media (min-width: 112.5em) {
			@content;
		}
	}
}
