// CONTACT SECTION
.contact {
	height: auto;
	width: auto;
	padding: 12rem 20rem 5rem 20rem;

	@include respond(tab-land) {
		padding: 2.5rem 15rem;
	}

	@include respond(tab-port) {
		padding: 3.5rem 8rem;
	}

	@include respond(phone) {
		padding: 3rem;
	}

	@include respond(big-desktop) {
		padding: 0 25rem;
	}

	&__header {
		text-align: center;
	}

	&__list {
		display: flex;
		justify-content: space-around;
		list-style: none;
		padding: 3rem 3rem 5rem 3rem;
	}

	&__icon svg {
		width: 3rem;
		height: 3rem;

		@include respond(tab-port) {
			width: 2.5rem;
			height: 2.5rem;
		}

		@include respond(phone) {
			width: 2.25rem;
			height: 2.25rem;
		}
	}

	&__icon,
	&__icon svg,
	&__icon a {
		&--email {
			fill: #ea4335;
		}
		&--github {
			fill: #181717;
		}
		&--linkedin {
			fill: #0a66c2;
		}
		&--twitter {
			fill: #1da1f2;
		}
		&--phone {
			fill: #25d366;
		}
	}
}
