// FOOTER SECTION
.footer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-size: 1.2rem;
	text-align: center;
	padding: 2rem;
	color: grey;

	@include respond(phone) {
		font-size: 1rem;
		flex-direction: column;
	}
}
