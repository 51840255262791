@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/base';
@import 'base/animations';
@import 'base/typography';

@import 'layout/footer';
@import 'layout/header';
@import 'layout/navigation';

@import 'pages/home-page/welcome';
@import 'pages/home-page/about';
@import 'pages/home-page/skills';
@import 'pages/home-page/projects';
@import 'pages/home-page/contact';

@import 'pages/project-page/logviewer';
