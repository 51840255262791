*, :after, :before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

@media (max-width: 37.5em) {
  html {
    font-size: 30%;
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  line-height: 1.35;
}

.container {
  background-color: #fff;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes typingfade {
  0% {
    color: #fff;
  }

  100% {
    color: #000;
  }
}

@keyframes backgroundfade {
  0% {
    background-color: #1c1919;
  }

  100% {
    background-color: #0000;
  }
}

.nav__logo {
  opacity: 0;
  padding: 0 2rem;
  font-size: 2.5rem;
  font-weight: 500;
  list-style: none;
}

@media (max-width: 37.5em) {
  .nav__logo {
    padding-right: 2rem;
    font-size: 2rem;
  }
}

.nav__item {
  opacity: 0;
  padding: 0 2rem;
  font-size: 1.8rem;
  font-weight: 300;
  list-style: none;
}

@media (max-width: 37.5em) {
  .nav__item {
    padding: 0 .5rem;
    font-size: 1.5rem;
  }
}

.nav a {
  color: #666565;
  text-decoration: none;
}

.welcome__text {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  color: #000;
  opacity: 1;
  background-color: #000;
  border-radius: 15px;
  flex-direction: column;
  padding: 5rem;
  animation: typingfade 3s ease-in forwards, backgroundfade 1s ease-in 1s forwards;
  display: flex;
  position: absolute;
  left: 0%;
}

@media (max-width: 56.25em) {
  .welcome__text {
    border-radius: 0;
  }
}

@media (max-width: 37.5em) {
  .welcome__text {
    border-radius: 0;
    padding: 2rem;
  }
}

.welcome__text--holder {
  width: 70%;
}

.welcome__text--main, .welcome__text--intro {
  width: 75%;
  font-size: 5rem;
  font-weight: 100;
}

@media (max-width: 56.25em) {
  .welcome__text--main, .welcome__text--intro {
    font-size: 4.25rem;
  }
}

@media (max-width: 37.5em) {
  .welcome__text--main, .welcome__text--intro {
    font-size: 2.5rem;
  }
}

.about__header {
  text-align: center;
}

h2 {
  font-size: 3rem;
  font-weight: 100;
}

@media (max-width: 37.5em) {
  h2 {
    font-size: 2.5rem;
  }
}

h3 {
  margin: 0;
  font-size: 2.35rem;
  font-weight: 300;
}

h4 {
  margin-bottom: .25rem;
  font-size: 2rem;
  font-weight: 300;
}

p {
  text-align: justify;
  margin-top: .5rem;
  margin-bottom: .75rem;
}

.footer {
  text-align: center;
  color: gray;
  flex-direction: row;
  justify-content: center;
  padding: 2rem;
  font-size: 1.2rem;
  display: flex;
}

@media (max-width: 37.5em) {
  .footer {
    flex-direction: column;
    font-size: 1rem;
  }
}

.header {
  width: 100%;
  opacity: 1;
  z-index: 3;
  background-color: #fff;
  position: sticky;
  top: 0;
}

.nav-holder {
  background-color: #fff;
}

.nav {
  height: 2.5vh;
  background-color: #fff;
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  display: flex;
}

.appear {
  animation: appear 3s ease-out 1s forwards;
}

.welcome {
  width: 95vw;
  height: 85vh;
  background-image: url("plants-SML.5671dd28.jpg");
  background-position: right 0 top 0;
  background-size: cover;
  border-radius: 1rem;
  justify-content: center;
  margin: auto;
  position: relative;
}

.welcome__scroll--label {
  opacity: 0;
  font-size: 1.75rem;
  transition: all 1s ease-out;
  position: absolute;
  bottom: .75rem;
  right: 1rem;
}

@media (max-width: 37.5em) {
  .welcome__scroll--label {
    display: none;
  }
}

@media (max-width: 56.25em) {
  .welcome {
    height: 60vh;
    border-radius: 0;
  }
}

@media (max-width: 37.5em) {
  .welcome {
    height: 40vh;
    border-radius: 0;
  }
}

.fade {
  color: #0000;
}

.cursor {
  border-right: .3rem solid #777;
}

.about {
  box-sizing: border-box;
  color: #666565;
  flex-direction: column;
  padding: 8rem 20rem 0;
  font-size: 1.75rem;
  display: flex;
}

@media (max-width: 75em) {
  .about {
    padding: 2.5rem 15rem;
  }
}

@media (max-width: 56.25em) {
  .about {
    padding: 2.5rem 8rem;
  }
}

@media (max-width: 37.5em) {
  .about {
    padding: 3rem;
  }
}

@media (min-width: 112.5em) {
  .about {
    padding: 0 25rem;
  }
}

.skills {
  box-sizing: border-box;
  color: #666565;
  padding: 2.5rem 20rem 5rem;
  font-size: 1.75rem;
}

@media (max-width: 75em) {
  .skills {
    padding: 2.5rem 15rem;
  }
}

@media (max-width: 56.25em) {
  .skills {
    padding: 0 8rem;
  }
}

@media (max-width: 37.5em) {
  .skills {
    padding: 0 3rem;
  }
}

@media (min-width: 112.5em) {
  .skills {
    padding: 5rem 25rem;
  }
}

.skills__header {
  text-align: center;
}

.skills__list {
  justify-content: space-around;
  padding: 3rem 0;
  list-style: none;
  display: flex;
}

.skills__icon svg {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 37.5em) {
  .skills__icon svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.skills__icon--html {
  fill: #e34f26;
}

.skills__icon--css {
  fill: #1572b6;
}

.skills__icon--sass {
  fill: #c69;
}

.skills__icon--js {
  fill: #f7df1e;
}

.skills__icon--react {
  fill: #61dafb;
}

.skills__icon--ts {
  fill: #3178c6;
}

.skills__icon--git {
  fill: #f05032;
}

li {
  width: 16.66%;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  display: flex;
}

.projects {
  color: #666565;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 20rem;
  display: flex;
  position: relative;
}

@media (max-width: 75em) {
  .projects {
    padding: 2.5rem 15rem;
  }
}

@media (max-width: 56.25em) {
  .projects {
    padding: 0 8rem;
  }
}

@media (max-width: 37.5em) {
  .projects {
    padding: 0 3.5rem;
  }
}

@media (min-width: 112.5em) {
  .projects {
    padding: 0 25rem;
  }
}

.projects__header--text, .projects__main--header {
  text-align: center;
}

.projects__main--image {
  width: 100%;
  margin: 5rem auto 10rem;
  transition: all .2s ease-in;
  display: block;
  box-shadow: 0 12px 12px #0003;
}

@media (max-width: 37.5em) {
  .projects__main--image {
    margin: 5rem auto;
  }
}

.projects__main--image:hover {
  transform: scale(1.01);
}

.projects__main--description {
  font-size: 1.75rem;
}

.projects__sub--header {
  text-align: center;
}

.projects__sub--image {
  width: 50%;
  margin: 3rem auto;
  transition: all .2s ease-in;
  display: block;
  box-shadow: 0 12px 12px #0003;
}

@media (max-width: 37.5em) {
  .projects__sub--image {
    width: 75%;
    margin: 3rem auto;
  }
}

.projects__sub--image:hover {
  transform: scale(1.01);
}

.projects__sub--description {
  font-size: 1.75rem;
}

.projects__sub--story {
  flex-direction: column;
  margin: 5rem auto;
  display: flex;
}

.projects__sub--text {
  padding: 0 0;
}

.projects__tiles {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 5rem;
  display: grid;
}

@media (min-width: 112.5em) {
  .projects__tiles {
    padding: 5rem 0;
  }
}

@media (max-width: 37.5em) {
  .projects__tiles {
    width: 80%;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    display: flex;
  }
}

.projects__tiles--image-1, .projects__tiles--image-2, .projects__tiles--image-3, .projects__tiles--image-4 {
  width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
  transition: transform .2s ease-in-out;
  box-shadow: 0 12px 12px #0003;
}

.projects__tiles--image-1:hover, .projects__tiles--image-2:hover, .projects__tiles--image-3:hover, .projects__tiles--image-4:hover {
  transform: scale(1.015);
}

.projects__tiles--header-1, .projects__tiles--header-2, .projects__tiles--header-3, .projects__tiles--header-4 {
  text-align: center;
}

.contact {
  height: auto;
  width: auto;
  padding: 12rem 20rem 5rem;
}

@media (max-width: 75em) {
  .contact {
    padding: 2.5rem 15rem;
  }
}

@media (max-width: 56.25em) {
  .contact {
    padding: 3.5rem 8rem;
  }
}

@media (max-width: 37.5em) {
  .contact {
    padding: 3rem;
  }
}

@media (min-width: 112.5em) {
  .contact {
    padding: 0 25rem;
  }
}

.contact__header {
  text-align: center;
}

.contact__list {
  justify-content: space-around;
  padding: 3rem 3rem 5rem;
  list-style: none;
  display: flex;
}

.contact__icon svg {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 56.25em) {
  .contact__icon svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 37.5em) {
  .contact__icon svg {
    width: 2.25rem;
    height: 2.25rem;
  }
}

.contact__icon--email, .contact__icon svg--email, .contact__icon a--email {
  fill: #ea4335;
}

.contact__icon--github, .contact__icon svg--github, .contact__icon a--github {
  fill: #181717;
}

.contact__icon--linkedin, .contact__icon svg--linkedin, .contact__icon a--linkedin {
  fill: #0a66c2;
}

.contact__icon--twitter, .contact__icon svg--twitter, .contact__icon a--twitter {
  fill: #1da1f2;
}

.contact__icon--phone, .contact__icon svg--phone, .contact__icon a--phone {
  fill: #25d366;
}

.logviewer {
  flex-direction: column;
  padding: 10rem 25rem 0;
  font-size: 1.75rem;
  line-height: 150%;
  display: flex;
}

@media (max-width: 75em) {
  .logviewer {
    padding: 2.5rem 15rem;
  }
}

@media (max-width: 56.25em) {
  .logviewer {
    padding: 2.5rem 8rem;
  }
}

@media (max-width: 37.5em) {
  .logviewer {
    padding: 3rem;
  }
}

@media (min-width: 112.5em) {
  .logviewer {
    padding: 0 25rem;
  }
}

.logviewer__header {
  text-align: center;
  margin: 5rem 0;
}

@media (max-width: 37.5em) {
  .logviewer__header {
    margin: 2rem 0;
  }
}

.logviewer__image--screenshot {
  width: 100%;
  margin: 2.5rem auto 5rem;
  box-shadow: 0 12px 12px #0003;
}

.logviewer__image--figma {
  width: 50%;
  margin-right: 3rem;
  box-shadow: 0 12px 12px #0003;
}

@media (max-width: 56.25em) {
  .logviewer__image--figma {
    width: 80%;
    margin: 2rem auto;
  }
}

.logviewer__image--color-focus {
  width: 50%;
  margin-left: 3rem;
  box-shadow: 0 12px 12px #0003;
}

@media (max-width: 56.25em) {
  .logviewer__image--color-focus {
    width: 80%;
    margin: 2rem auto;
  }
}

.logviewer__image--mobile-view {
  height: 45vh;
  width: auto;
  margin: 1rem 5rem;
  box-shadow: 0 12px 12px #0003;
}

.section__tools {
  flex-direction: row;
  align-items: center;
  margin: 5rem 0;
  display: flex;
}

@media (max-width: 56.25em) {
  .section__tools {
    flex-direction: column-reverse;
  }
}

@media (max-width: 37.5em) {
  .section__tools {
    margin: 3rem 0;
  }
}

.section__tools-content {
  flex-direction: column;
  display: flex;
}

.section__process {
  flex-direction: row;
  align-items: center;
  margin: 5rem 0;
  display: flex;
}

@media (max-width: 56.25em) {
  .section__process {
    flex-direction: column;
  }
}

@media (max-width: 37.5em) {
  .section__process {
    margin: 3rem 0;
  }
}

.section__process-content {
  flex-direction: column;
  display: flex;
}

.section__build {
  flex-direction: row;
  align-items: center;
  margin: 5rem 0;
  display: flex;
}

@media (max-width: 56.25em) {
  .section__build {
    flex-direction: column-reverse;
  }
}

@media (max-width: 37.5em) {
  .section__build {
    margin: 3rem 0;
  }
}

.section__build-content {
  flex-direction: column;
  display: flex;
}

.section__learned {
  margin: 5rem 0;
}

@media (max-width: 37.5em) {
  .section__learned {
    margin: 3rem 0;
  }
}

.closing-p {
  margin-top: 3rem;
}

.disclaimer {
  margin-top: 3rem;
  font-size: 1rem;
}

/*# sourceMappingURL=index.150cad09.css.map */
