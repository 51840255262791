// ABOUT SECTION
.about {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	font-size: $text-font;
	color: $text-color;
	padding: 8rem 20rem 0 20rem;

	@include respond(tab-land) {
		padding: 2.5rem 15rem;
	}

	@include respond(tab-port) {
		padding: 2.5rem 8rem;
	}

	@include respond(phone) {
		padding: 3rem;
	}

	@include respond(big-desktop) {
		padding: 0 25rem;
	}
}
