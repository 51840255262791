// PROJECTS SECTION
.projects {
	position: relative;
	display: flex;
	flex-direction: column;
	color: $text-color;
	margin: 0 auto;
	padding: 0 20rem;

	@include respond(tab-land) {
		padding: 2.5rem 15rem;
	}

	@include respond(tab-port) {
		padding: 0 8rem;
	}

	@include respond(phone) {
		padding: 0 3.5rem;
	}

	@include respond(big-desktop) {
		padding: 0 25rem;
	}

	&__header--text {
		text-align: center;
	}

	&__main {
		&--header {
			text-align: center;
		}

		&--image {
			display: block;
			width: 100%;
			margin: 5rem auto 10rem auto;
			box-shadow: $image-shadow;
			transition: all ease-in 0.2s;

			@include respond(phone) {
				margin: 5rem auto 5rem auto;
			}
		}

		&--image:hover {
			transform: scale(1.01);
		}

		&--description {
			font-size: $text-font;
		}
	}

	&__sub {
		&--header {
			text-align: center;
		}
		&--image {
			display: block;
			width: 50%;
			margin: 3rem auto;
			box-shadow: $image-shadow;
			transition: all ease-in 0.2s;

			@include respond(phone) {
				margin: 3rem auto;
				width: 75%;
			}
		}

		&--image:hover {
			transform: scale(1.01);
		}

		&--description {
			font-size: $text-font;
		}

		&--story {
			margin: 5rem auto 5rem auto;
			display: flex;
			flex-direction: column;
		}

		&--text {
			// text-align: center;
			//FIXME
			padding: 0 0rem;
		}
	}

	&__tiles {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		gap: 5rem;

		@include respond(big-desktop) {
			padding: 5rem 0;
		}

		@include respond(phone) {
			gap: 2rem;
			display: flex;
			flex-direction: column;
			width: 80%;
			margin: 0 auto;
		}

		&--image-1,
		&--image-2,
		&--image-3,
		&--image-4 {
			width: 100%;
			margin-top: 1rem;
			border-radius: 5px;
			box-shadow: $image-shadow;
			transition: transform 0.2s ease-in-out;
		}

		&--image-1:hover,
		&--image-2:hover,
		&--image-3:hover,
		&--image-4:hover {
			transform: scale(1.015);
		}

		&--header-1,
		&--header-2,
		&--header-3,
		&--header-4 {
			text-align: center;
		}
	}
}
